//http.js文件
import axios from 'axios'
// import Message from 'element-ui';
// 数据引入
let service = axios.create({
  timeout: 20000
  // withCredentials: true,//跨域请求时是否携带凭证信息（如cookies和HTTP认证信息）。这个选项的默认值是false。
})
 
//配置请求时的拦截器
service.interceptors.request.use(
  config=> {
    config.headers.Authorization = localStorage.getItem('token')
    return config
  },
  error => {
    // Do something with request error
    console.log('request error: ', error) // for debug
    Promise.reject(error)
  }
)
 
//配置响应时的拦截器
service.interceptors.response.use(
  response => {
    //比如约定code === 1417时， token 过期，code为1001时，登录失效。
    if (response.data.code === 1417) {
 
       //此处写我们的逻辑代码....
 
    } else if (response.data.code === 1001) {
    //   Message.error('登录信息已过期,请重新登录')
      alert('登录信息已过期,请重新登录')
      window.sessionStorage.clear();
 
     //重新登录
      window.location.href = "http://xxxx.xx.login";
    }
    return response && response
  }
)
//下面为配置我们的请求方式，post，get，put，等
export default {
  post(url, data, params) {
    return service({
      headers: {
        'token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      method: 'post', // 请求协议
      url: url, // 请求的地址
      data: JSON.stringify(data), // post请求的数据
      params,
      timeout: 30000 // 超时时间,单位毫秒
    }).then(
      (res) => {
        return res.data
      }
    )
  },
  get(url, params) {
    return service({
      headers: {
        'token': sessionStorage.getItem('token')
      },
      method: 'get',
      url: url,
      params, // get请求时带的参数
      timeout: 30000
    }).then(
      (res) => {
        return res.data
      }
    )
  },
  put(url, data, params) {
    return service({
      headers: {
        'token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      method: 'put', // 请求协议
      url: url, // 请求的地址
      data: JSON.stringify(data), // post请求的数据
      params,
      timeout: 30000 // 超时时间,单位毫秒
    }).then(
      (res) => {
        return res.data
      }
    )
  },
//删除
  del(url, params) {
    return service({
      // headers: {
      //     'token': sessionStorage.getItem('token')
      // },
      method: 'delete', // 请求协议
      url: url, // 请求的地址
      params,
      timeout: 30000 // 超时时间,单位毫秒
    }).then(
      (res) => {
        return res.data
      }
    )
  },
//下载
  download(url, params) {
    return axios.get(url, {
      params,
      headers: {
        token: sessionStorage.getItem("token")
      },
      responseType: 'blob'
    }
      //请求头配置
    );
  },
//自定义请求方式，该处为获取token，不带header参数的get请求，可自行配置。
  getToken(url, params) {
    return service({
      method: 'get',
      url: url,
      params, // get请求时带的参数
      timeout: 30000
    }).then(
      (res) => {
        return res.data
      }
    )
  },
}