<template>
  <div class="chinaMap"></div>
</template>

<script>
import "@/lib/china.js";

export default {
  name: "",
  data() {
    return {
      myChart: null,
      mapData:[], // 地图数据
      allTotal:{} // 订单设备金额所有总数
    };
  },
  methods: {
    setMap() {
      // var mapData = [
      //   { name: "北京", value: [116.46, 39.92], num:23231, phone: "15918523709" },
      //   { name: "上海", value: [121.48, 31.22], num:123, phone: "15918523709" },
      //   { name: "天津", value: [117.2, 39.13],  num:24214, phone: "15918523709" },
      //   { name: "重庆", value: [106.54, 29.59], num:465, phone: "15918523709" },
      //   { name: "广东", value: [113.23, 23.16], num:34, phone: "15918523709" },
      //   { name: "吉林", value: [126.57, 43.87], num:66, phone: "15918523709" },
      //   { name: "海口", value: [110.35, 20.02], num:453, phone: "15918523709" },
      // ];
      // 接口统计数据
      // const nums = {
      //   a: 2432,
      //   b: 23424,
      //   c: 9999,
      //   d: 199,
      //   e: 200,
      //   f: 322,
      // };
      let option = {
        title: [
          {
            text: `设备总数 {a| ${this.allTotal.deviceTotal}}`,
            top: 0,
            left: "7%",
            textStyle: {
              color: "#6dd0e3",
              fontSize: 16,
              fontWeight: "normal",
              rich: {
                a: {
                  color: "#10AEB5",
                  fontSize: 16,
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: "#163794",
                },
                c: {
                  fontSize: 12,
                  color: "#E64546",
                },
              },
            },
          },
          {
            text: `订单总数 {a| ${this.allTotal.orderTotal}}`,
            top: 0,
            left: "40%",

            textStyle: {
              color: "#6dd0e3",
              fontSize: 16,
              fontWeight: "normal",
              rich: {
                a: {
                  color: "#10AEB5",
                  fontSize: 16,
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: "#163794",
                },
                c: {
                  fontSize: 12,
                  color: "#FF9985",
                },
              },
            },
          },
          {
            text: `总金额 {a| ${this.allTotal.totalMoney}}`,
            top: 0,
            right: "7%",
            textStyle: {
              color: "#6dd0e3",
              fontSize: 16,
              fontWeight: "normal",
              rich: {
                a: {
                  color: "#10AEB5",
                  fontSize: 16,
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: "#163794",
                },
                c: {
                  fontSize: 12,
                  color: "#476DA0",
                },
              },
            },
          },
          {
            text: `蜡烛台 {a| ${this.allTotal.candlestickTotal}}`,
            top: "10%",
            left: "7%",
            textStyle: {
              color: "#6dd0e3",
              fontSize: 16,
              fontWeight: "normal",
              rich: {
                a: {
                  color: "#10AEB5",
                  fontSize: 16,
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: "#163794",
                },
                c: {
                  fontSize: 12,
                  color: "#B80909",
                },
              },
            },
          },
          {
            text: `莲花灯 {a| ${this.allTotal.lampTotal}}`,
            top: "10%",
            left: "40%",
            textStyle: {
              color: "#6dd0e3",
              fontSize: 16,
              fontWeight: "normal",
              rich: {
                a: {
                  color: "#10AEB5",
                  fontSize: 16,
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: "#163794",
                },
                c: {
                  fontSize: 12,
                  color: "#10AEB5",
                },
              },
            },
          },
          {
            text: `礼炮 {a| ${this.allTotal.maroonTotal}}`,
            top: "10%",
            right: "7%",

            textStyle: {
              color: "#10AEB5",
              fontSize: 16,
              fontWeight: "normal",
              rich: {
                a: {
                  color: "#10AEB5",
                  fontSize: 16,
                },
                b: {
                  fontSize: 12,
                  lineHeight: 20,
                  color: "#163794",
                },
                c: {
                  fontSize: 12,
                  color: "#4D5054",
                },
              },
            },
          },
        ],
        label: {
          normal: {
            show: true,
            // formatter: (params) => {
            //   return params.data.num;
            // },
            textStyle: {
              color: "#3096ff",
              fontSize: 12,
              backgroundColor: "#fff",
              shadowColor: "#999",
              shadowBlur: 8,
              shadowOffsetY: 5,
            },
            borderRadius: 24,
            padding: 4,
            width: 16,
            lineHeight: 16,
            align: "center",
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          // 鼠标移到图里面的浮动提示框
          show: true,
          backgroundColor: "#11367a", //提示标签背景颜色
          textStyle: {
            color: "#6dd0e3", //提示标签字体颜色
            fontSize: 10,
          },
          padding: [5, 10, 0, 10], // 设置上下的内边距为 5,0，左右的内边距为 10
          formatter(params, ticket, callback) {
            // params.data 就是series配置项中的data数据遍历
            let deviceTotalCount, orderCount, orderPrices;
            if (params.data) {
              deviceTotalCount = params.data.deviceTotalCount;
              orderCount = params.data.orderCount;
              orderPrices = params.data.orderPrices;
            } else {
              // 为了防止没有定义数据的时候报错写的
              deviceTotalCount = 0;
              orderCount = 0;
              orderPrices = 0;
            }
            let htmlStr = `<div style='font-size:12px;'> ${params.name}</div>
                            <p style='text-align:left;margin-top:4px;'>设备数：${deviceTotalCount}<br/>订单数：${orderCount}<br/>总金额：${orderPrices}</p>`;
            return deviceTotalCount===0?'':htmlStr;
          },
        },
        geo: {
          map: 'china',
          // top: '20%',
          label: {
              show: true,
              color: '#6dd0e3',
              fontSize: 10
          },
          emphasis: {
            show: true,
            label: {
              show: false,
            },
          },
          itemStyle: {
            areaColor: "#2043AA",
            borderColor: "#111",
          },
          roam: true, // 是否开启鼠标缩放和平移漫游
          zoom: 1.2, // 当前视角的缩放比例（地图的放大比例）
        },
        series: [
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            data: this.mapData,
            symbolSize: 10, // 圆圈大小
            symbol: 'circle', // 标记形状为圆
            // itemStyle: {
            //   normal: {
            //     color: '#FF0000' // 圆圈颜色 统一一种颜色
            //   }
            // },
            // showEffectOn: 'render',
            // emphasis: {
            //     scale: true
            // },
            itemStyle: { // 不同颜色区分
                color: function (params) {
                    var colorList = ['#FFA200', '#D6FC01', '#00D8FF', '#FF00CC', '#FF1200', '#f54a4a']
                    if (params.dataIndex <= 6) {
                        return colorList[params.dataIndex]
                    } else {
                        return colorList[params.dataIndex % 6]
                    }
                },
                shadowBlur: 10,
            },
            zlevel: 1,
            // name: "合作场所",
            // type: "map",
            
            // label: {// 圆圈显示数字
            //   position: "center",
            //   show: true,
            // },
            geoIndex: 0,
            // data: data,
          }
        ],
      };
      if (!this.myChart) this.myChart = this.$echarts(this.$el);

      this.myChart.clear();
      this.myChart.resize();
      this.myChart.setOption(option);
    },
  },
  mounted() {
    this.$urlApi.mapSummary({}).then((res) => {
      this.mapData = res.data;
      setTimeout(()=>{
        this.setMap();
      })
    });
    this.$urlApi.allTotal({}).then((res) => {
      this.allTotal = res.data;
    });
  },
};
</script>

<style lang="less" scoped>
.chinaMap {
  height: 100%;
}
</style>