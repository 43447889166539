<template>
    <div class="doubleBarChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            myChart: null,
            monthData:[], // 当前年的月度信息
            monthOrderTotal:[] // 当前年的月度订单总量
        }
    },
    methods: {
        setChart() {
            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    },
                    backgroundColor: '#11367a',
                    textStyle: {
                        color: '#6dd0e3',
                        fontSize: 10,
                    },
                },
                legend: [
                    {
                        top: '8%',
                        right: '5%',
                        itemWidth: 7,
                        itemHeight: 7,
                        textStyle: {
                            color: '#5CB1C1',
                        }
                    },
                ],
                grid:
                    {
                    top: "20%",
                    bottom: "15%",
                    left: 45,
                    right: 40,
                    },
                xAxis: [
                    {
                        type: 'category',
                        axisLabel: {
                            interval: 0,
                            color: '#61B9C8',
                            fontSize: 10
                        },
                        axisLine: {
                            symbol: ['none', 'arrow'],
                            symbolSize: [6, 6],
                            symbolOffset: [0, 5],
                            lineStyle: {
                                color: '#122C49'
                            }
                        },
                        axisTick: {show: false},
                        data: this.monthData
                    },
                ],
                yAxis:
                    {
                        type: 'value',
                        min: 0,
                        max: 300,
                        axisLabel: {
                            color: '#61B9C8',
                            fontSize: 9,
                            showMaxLabel: false,
                        },
                        axisLine: {
                            symbol: ['none', 'arrow'],
                            symbolSize: [6, 6],
                            symbolOffset: [0, 5],
                            lineStyle: {
                                color: '#122C49'
                            }
                        },
                        axisTick: {
                            length: 3
                        },
                        name: '(订单量)',
                        nameGap: -5,
                        nameTextStyle: {
                            color: '#61B9C8',
                            fontSize: 10,
                            align: 'right',
                            padding: [0, 6, 0, 0]
                        },
                        splitLine: {show: false}

                    },
                series: [
                    // {
                    //     name: '新增',
                    //     type: 'bar',
                    //     barWidth: 7,
                    //     stack: '总数',
                    //     itemStyle: {
                    //         color: {
                    //             type: 'linear',
                    //             x: 0,
                    //             y: 0,
                    //             x2: 0,
                    //             y2: 1,
                    //             colorStops: [{
                    //                 offset: 0, color: '#FC9386' // 0% 处的颜色
                    //             },
                    //                 {
                    //                     offset: 0.4, color: '#F87B86' // 40% 处的颜色
                    //                 }, {
                    //                     offset: 1, color: '#F36087' // 100% 处的颜色
                    //                 }],
                    //             global: false // 缺省为 false
                    //         }, //背景渐变色
                    //         borderRadius: [3.5, 3.5, 0, 0],
                    //     },
                    //     data: [120, 102, 101, 134, 190, 130, 120, 190, 130, 120]
                    // },
                    {
                        name: '总数',
                        type: 'bar',
                        barWidth: 7,
                        stack: '总数',
                        itemStyle: {
                            color: '#8C14EA',
                            borderRadius: [3.5, 3.5, 0, 0,],
                        },
                        data: this.monthOrderTotal
                    },

                ]
            };
            if (!this.myChart) this.myChart = this.$echarts(this.$el);
            this.myChart.clear();
            this.myChart.resize()
            this.myChart.setOption(option);
        }
    },
    mounted() {
        this.$urlApi.monthOrderTotal({}).then((res) => {
            this.monthOrderTotal = res.data.value;
            this.monthData = res.data.name;
            setTimeout(()=>{
                this.setChart();
            })
        });
    },
}
</script>

<style lang="less" scoped>
.doubleBarChart {
    width: 100%;
    height: 100%;
}
</style>
