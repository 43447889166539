//common.js文件
import axios from 'axios';
import urlApi from './api';
import httpAxios from './http';
 
//现在我们目标是要全局调用接口，就在Vue原型上添加属性：
 
export default {
    install(Vue) {
        Vue.prototype.$axios = axios;
        Vue.prototype.$urlApi = urlApi;
        Vue.prototype.$http = httpAxios;
    }
}