//api.js文件
//当我们切换地址时，则需要在正式、测试环境地址切换即可，打包发布也一样在此处切换接口环境。
//如下面登录接口中“/login”即为后端给的接口字段，安装此格式依次添加即可。
//post，get，del等，则为我们刚刚配置的请求方式，
 
import $http from './http';
 
//测试环境地址：
let BASE_URL = 'https://uat.hongfumanda.com';
 
//正式环境地址：
//let BASE_URL = "https://xxxx.xx"
 
//下面接口配置举例
export default {
  BASE_URL: BASE_URL,
  // 地图汇总接口
  mapSummary: (params) => $http.get(`${BASE_URL}/admin/chart/mapSummary`, params),
  // 服务中心排名10
  serviceCenterRanking:(params) => $http.get(`${BASE_URL}/admin/chart/serviceCenterRanking`, params),
  // 合作场所设备数
  cooperatePlaceDevice:(params) => $http.get(`${BASE_URL}/admin/chart/cooperatePlaceDevice`, params),
  // 订单设备金额所有总数
  allTotal:(params) => $http.get(`${BASE_URL}/admin/chart/allTotal`, params),
  // 月度订单统计
  monthOrderTotal:(params) => $http.get(`${BASE_URL}/admin/chart/monthOrderTotal`, params),
  // 月度设备统计
  monthDeviceTotal:(params) => $http.get(`${BASE_URL}/admin/chart/monthDeviceTotal`, params),
};