<template>
    <div class="pieChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            myChart: null
        }
    },
    methods: {
        setChart() {
            let option = {
                // title: [
                //     {
                //         text: "金额",
                //         center: '50%',
                //         bottom: '6%',
                //         textStyle: {
                //             color: "#fff",
                //             fontSize: 12
                //         }
                //     }
                // ],
                tooltip: {
                    trigger: 'item',
                    formatter: "{b} : {c} ({d}%)"
                },
                legend: {
                    data: [
                        {name: '蜡烛', icon: 'circle'},
                        {name: '莲花灯', icon: 'circle'},
                        {name: '礼炮', icon: 'circle'},
                        // {name: '其他', icon: 'circle'},
                    ],
                    right: "5%",
                    top: "45%",
                    itemWidth: 7,
                    itemHeight: 7,
                    textStyle: {
                        color: '#00CCFF',
                        fontSize: 10
                    },
                    orient:"vertical"
                },
                series: [
                    {
                        name: '【金额】',
                        type: 'pie',
                        radius: '60%', // 圆饼图大小
                        center: ['50%', '50%'], // 圆饼图位置
                        data: [
                            {value: 335, name: '蜡烛'},
                            {value: 310, name: '莲花灯'},
                            {value: 234, name: '礼炮'},
                            // {value: 135, name: '其他'}
                        ],
                        label: {
                            show:true,
                            formatter:"{b} : ({d}%)",// {c} 
                            fontSize: 12,
                            color: '#00CCFF'
                        },
                        labelLine: {
                            length: 15,
                            length2: 10,
                            lineStyle: {
                                color: '#3F3F5C'
                            }
                        },
                        itemStyle: {
                            color: function (params) {
                                var colorList = ['#F74F64', '#00CCFF', '#315371', '#142AFE', '#9814FE'];
                                return colorList[params.dataIndex];
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            if (!this.myChart) this.myChart = this.$echarts(this.$el);

            this.myChart.clear();
            this.myChart.resize()
            this.myChart.setOption(option);
            // // 随着屏幕大小调节图表
            // window.addEventListenter("resize",()=>{
            //     this.myChart.resize();
            // })

        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.pieChart {
    height: 100%;
    width: 100%;
    padding: 0 20px;
}
</style>
